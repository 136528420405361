export const MAUI_PRIMARY = {
  mountainMeadow: '#2ADE93',
  mountainMeadowRGB: '42,222,147',
  darkPurple: '#2E1679',
  purple40: '#5635BE',
  sunsetOrange: '#FF8E4F',
  sunsetOrangeRGB: '255,142,79',
  mangoYellow: '#FFC04F',
  mangoYellowRGB: '266,192,79',
  orangeYellowGradient: 'linear-gradient(270deg, #FF8E4F 0%, #FFC04F 100%)', //sunset orange => mangoYellow
} as const

export const MAUI_SECONDARY = {
  purpleBlueGradient: 'linear-gradient(270deg, #A47AD1 0%, #286BDF 100%)', // royal => blue40
  ruby: '#F41E60',
  rubyRGB: '244,30,96',
  blue40: '#286BDF',
  darkGray10: '#2D3540',
  darkGray20: '#516074',
  lightGray40: '#CCD2DC',
  lightGray60: '#F3F3F3',
  royal: '#A47AD1',
  lavender: '#D5CCEF',
  salt: '#F0EBFF',
  smoke: '#F1F7FF',
  white: '#FFFFFF',
} as const

export const OLD_REPLACE_ME = {
  blueGreenGradient: 'linear-gradient(270deg, #2ade93 0%, #44c1ea 100%)', // mountainMeadow => pictonBlue
  mimiPink: '#FCD9E9',
  mountainMeadow: '#2ade93',
  middleRedPurple: '#110630',
  hanBlue: '#3d74d5',
  ruby: '#f41e60',
  taupeGray: '#8f8f8f',
  isabelline: '#eaeaea',
  graniteGray: '#665f79',
  pastelBlue: '#aeb7c5',
  azureishWhite: '#dae1ea',
  whiteSmoke: '#f4f7fb',
  ghostWhite: '#f9fbfe',
  lightSeaGreen: '#1ea99d',
  duckEggGreen: '#eefcf6',
  mountainMeadowLight: '#54e4a8',
  mantis: '#74d861',
  crayola: '#20b474',
  pearlAqua: '#7eeabd',
  aeroBlue: '#c7f6e1',
  mustard: '#ffe652',
  gargoyleGas: '#ffda40',
  pictonBlue: '#44c1ea',
  mediumSkyBlue: '#8adeec',
  hanBlueDark: '#0c3988',
  hanBlueLight: '#4f8edb',
  spanishViolet: '#47257d',
  iris: '#5d2dc5',
  mediumPurple: '#8f71f4',
  cyclamen: '#ed77a2',
  white: '#ffffff',
  paleGrey: '#f4f7fb',
  paleGreyTwo: '#f1f4f7',
  paleGreyThree: '#dae1ea',
} as const

export const BrandColors = {
  ...OLD_REPLACE_ME,
  ...MAUI_PRIMARY,
  ...MAUI_SECONDARY,
} as const

export type BrandColor = keyof typeof BrandColors
